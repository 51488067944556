@import "variables_mixin";

html.calendarIsVisible{
  overflow: hidden;
}

.bookingform-wrapper-overlay {
  display:none;
  position: fixed;
  z-index:9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000,.5);
  &.visible {
    display: block;
  }
  .bookingform-wrapper-overlay-internal{
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }
}
.bookingform-wrapper {
  position: relative;
  z-index: 9999;
  width: 100%;
  max-width: 1060px;
  min-height: 460px;
  top: 0;
  left: 0;
  font-family: sans-serif;
  @include boxSizing();
  @include media_Mobile() {
    max-width: 700px;
  }
  * {
    @include boxSizing();
  }
  form {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(stretch);
    @include flex-wrap(wrap);
    width: 100%;
    margin: 0 auto;
    padding: 0;
    @include media_SmallMobile() {
      display: block;
    }
  }
  .main_col {
    padding-top: 40px;
    padding-bottom: 40px;
    @include flex-grow(1);
    @include media_Mobile(){
      width: auto;
    }
    @include media_SmallMobile() {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .side {
    width: 200px;
    border: none;
    min-height: 400px;
    padding: 10px 20px 20px;
    font-size: 12px;
    @include media_SmallMobile() {
      width: 100%;
      padding: 20px;
    }
    .selectors {
      display: none;
    }
    .fields {
      @include media_SmallMobile() {
        max-width: 400px;
        margin-right: auto;
        margin-left: auto;
      }
      .field {
        $selectHeight: 25px;
        position: relative;
        padding: 5px 0;
        border-bottom: 1px solid #ccc;
        label {
          display: block;
          width: 100%;
          margin-bottom: 5px;
          line-height: 130%;
        }
        select {
          @include select();
          width: 100%;
          line-height: $selectHeight;
          height: $selectHeight;
          text-align: right;
          text-align-last: right;
          padding: 0 20px;
          position: relative;
          z-index: 10;
          background-color: transparent;
          font-size: 12px;
          &:focus {
            outline: 1px solid #ddd;
          }
          option {
            direction: rtl;
            background: #fff;
            color: #333;
          }
        }
        p.value {
          line-height: $selectHeight;
          margin: 0;
          padding: 0;
          text-align: right;
        }

        &.select_hotel,
        &.select_treatment,
        &.adults,
        &.children,
        &.rooms {
          > div {
            position: relative;
            height: $selectHeight;
            &:after {
              content: '\025BE';
              position: absolute;
              top: 0;
              right: 0;
              line-height: $selectHeight;
              height: $selectHeight;
              width: 20px;
              font-size: 18px;
              text-align: right;
              z-index: 1;
            }
          }
        }
        &.iata {
          input {
            @include input();
            width: 100%;
            line-height: $selectHeight;
            height: $selectHeight;
            background-color: transparent;
            font-size: 12px;
            text-transform: none;
          }
        }
        &.bestprice {
          border-bottom: 0;
          height: 60px;
          @include media_SmallMobile(){
            height: 80px;
          }
          #bestprice {
            line-height: 30px;
            font-size: 24px;
            text-align: right;
          }
          #bestprice-loading {
            margin: 0;
            width: 30px;
            position: absolute;
            right: 0;
            bottom: 20px;
            background-color: #000;
            &:after {
              content: '...';
            }
          }
        }
        &.booknowbuttoncontainer {
          border-bottom: 0;
          padding-bottom: 0;
          input {
            @include input();
            width: 100%;
            height: 50px;
            text-align: center;
            cursor: pointer;
          }
          .quick-search-cancel {
            display: block;
            line-height: 130%;
            font-size: 13px;
            text-decoration: none;
            text-align: center;
            width: 100%;
            margin-top: 20px;
            text-transform: none;
            letter-spacing: 0
          }
        }
      }
    }
  }
  &.visible {
    display: block
  }
  .close {
    display: block;
    cursor: pointer;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 60px;
      content: "X";
      text-align: center;
      font-size: 20px;
      font-family: sans-serif
    }
  }
  .calendar_title {
    width: 100%;
    height: 60px;
    padding: 0 40px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    line-height: 60px;
  }

  #booking-calendar {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    @include media_Mobile(){
      max-width: 400px;
    }
    * {
      @include boxShadow(none);
      text-shadow: none;
    }
    .ui-datepicker.ui-datepicker-inline {
      width: 100% !important;
      border: none;
      padding: 0;
      background: transparent;
      @include borderRadius(0);
      @include boxShadow(none);
      .ui-datepicker-group {
        width: 49%;
        margin-right: 0;
        margin-left: 0;
        &.ui-datepicker-group-first {
          float: left;
        }
        &.ui-datepicker-group-last {
          float: right;
        }
      }
      .ui-datepicker-header {
        border: 0;
        margin: 0;
        padding: 0;
        height: 40px;
        background: none;
        position: relative;
        @include borderRadius(0);
        .ui-datepicker-title {
          margin: 0;
          padding: 0;
          line-height: 40px;
        }
        .ui-corner-all {
          background: none;
          height: 40px;
          width: 40px;
          top: 0;
          position: absolute;
          @include borderRadius(0);
          @include transition();
          &:after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 40px;
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
          }
          &.ui-state-hover {
            border: none;
          }
          &:not(.ui-state-disabled) {
            cursor: pointer;
          }
          span {
            display: none;
          }
        }
        .ui-datepicker-prev {
          left: 0;
          &:after {
            content: '\27F5';
          }
          &.ui-datepicker-prev-hover.ui-state-hover {
            left: 0;
            top: 0;
          }
        }
        .ui-datepicker-next {
          right: 0;
          &:after {
            content: '\027F6';
          }
          &.ui-datepicker-next-hover.ui-state-hover {
            right: 0;
            top: 0;
          }
        }
        .ui-state-disabled {
          @include opacity(0.3);
        }
      }
    }

    .ui-datepicker-calendar {
      width: 90%;
      border:none;
      background: none;
      padding: 0;
      margin: 0;
      @include media_SmallMobile() {
        width: 100%;
      }
      .ui-state-disabled {
        pointer-events: auto !important;
      }
      thead {
        background: none;
        border-bottom: 0;
        th {
          padding: 1em .3em 2em;
          text-align: center;
          font-weight: normal;
          border: none;
          border-collapse: collapse
        }
      }
      thead, tbody {
        tr {
          border: none;
        }
      }
      tbody {
        td {
          border: none;
          padding: 1px;
          margin: 0;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          font-size: 18px;
          background: transparent none;
          &.zeroed .ui-state-default:before {
            content: '0';
          }
          &.last-room-available,
          &.best-price {
            &:after {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6px;
              border-bottom-color: transparent;
              border-left-color: transparent;
              position: absolute;
              right: 1px;
              top: 1px;
              content: '';
              @include media_SmallMobile() {
                right: 3px;
              }
            }
          }
          &.last-room-available:after {
            border-top-color: red;
            border-right-color: red;
          }
          &.best-price:after {
            border-top-color: green;
            border-right-color: green;
          }
          &.last-room-available.best-price:after {
            border-top-color: green;
            border-right-color: red;
          }
          &.min-stay {
            > .ui-state-default:after {
              content: '';
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 3px;
              background: transparent;
              left: 0;
              display: block;
            }
          }
          &.date-in-range {
            &.range-end {
              @include opacity(0.6);
            }
            .price {
              display: none;
            }
            .price_pn {
              display: block;
            }
          }
          .ui-state-default {
            border: 0;
            line-height: 26px;
            text-align: center;
            width: 55px;
            height: 55px;
            display: block;
            font-size: 16px;
            position: relative;
            @include media_SmallDesktop(){
              width: 50px;
            }
            @include media_ExtraSmallMobile() {
              width: 45px;
            }
          }
          span.data {
            position: absolute;
            bottom: 10px;
            font-size: 11px;
            height: auto;
            width: 100%;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            line-height: 1em;
            cursor: pointer;
            background: transparent;
          }
        }
      }
    }
  }
  .legend_wrap .legend {
    margin: 30px auto 0;
    width: 95%;
    text-align: center;
    .best,
    .last,
    .minimum_stay{
      font-size: 13px;
      margin: 0;
      padding-left: 25px;
      display: inline-block;
      position: relative;
    }
    .best,
    .last {
      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        width: 0;
        height: 0;
        border: {
          style: solid;
          width: 6px;
          color: transparent;
        }
      }
    }
    .minimum_stay {
      &:before {
        margin-bottom: 3px;
        margin-right: 10px;
        content: "";
        display: inline-block;
        width: 12px;
        background: #000;
        height: 3px;
      }
    }
    .best {
      margin-right: 20px
    }
    .best:before {
      border-top-color: green;
      border-right-color: green;
    }
    .last:before {
      border-top-color: red;
      border-right-color: red;
    }
  }
  #children-ages {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    @include media_Mobile(){
      width: 90%;
    }
    @include media_SmallMobile() {
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
    }
    .label {
      display: block;
      margin-bottom: 20px;
      @include media_SmallMobile(){
        max-width: 400px;
        margin-right: auto;
        margin-left: auto;
      }
    }
    .child-list {
      $childSelectHeight: 30px;
      ul {
        list-style: none inside none;
        margin: 0;
        padding: 0;
        @include flexbox();
        @include flex-direction(row);
        @include flex-wrap(wrap);
        @include media_SmallMobile() {
          @include flex-direction(column);
          max-width: 400px;
          margin-right: auto;
          margin-left: auto;
        }
        li {
          margin-left: 20px;
          position: relative;
          max-width: 100px;
          @include flex-grow(1);
          &:first-child {
            margin-left: 0;
          }
          @include media_SmallMobile() {
            margin-left:0;
            margin-bottom: 10px;
            max-width: 100%;
            @include flexbox();
            @include flex-direction(row);

          }
          label {
            display: block;
            width: 100%;
            margin-bottom: 10px;
            line-height: 130%;
            white-space: nowrap;
            @include media_SmallMobile() {
              @include flex-grow(1);
              width: auto;
              margin-bottom: 0;
              line-height: $childSelectHeight;
            }
          }
          select {
            @include select();
            width: 100%;
            line-height: $childSelectHeight;
            height: $childSelectHeight;
            text-align: right;
            text-align-last: right;
            padding: 0 25px 0 10px;
            position: relative;
            z-index: 10;
            background-color: transparent;
            border: 1px solid #ccc;
            option {
              direction: rtl;
              background: #fff;
              color: #333;
            }
          }
          > span {
            display: block;
            position: relative;
            height: $childSelectHeight;
            @include media_SmallMobile() {
              @include flex-grow(1);
            }
            &:after {
              content: '\025BE';
              position: absolute;
              top: 0;
              right: 5px;
              line-height: $childSelectHeight;
              height: $childSelectHeight;
              width: 20px;
              font-size: 18px;
              text-align: right;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
